<template>
  <div :class="['pecialization_layer', isHide ? 'hide' : '']">
		<div class="pecialization_center">
			<img :src="g_global_cdn + '/img/pc/images/index/pecialization-service.f4556a15eb9ce655.png'" alt="" class="pecialization_pic">
			<div class="pecialization_center_text">
				<p class="pecialization_center_text_line1">不知道选哪款产品？</p>
				<p class="pecialization_center_text_line2">专属顾问为您定制保障方案</p>
			</div>
			<a class="pecialization_btn" href="https://www.xiaoyusan.com/promotion/page?pageid=a843f52dd4&chn=baidu-pz-jbp-xxsj-maddie-1v1zx-pc-xys-xys-wy-gw-1" target="_blank">立即预约</a>
			<img :src="g_global_cdn + '/img/pc/images/pecialization-close.410dbb1a3ba8428a.png'" alt="" class="pecialization_close" @click="close">
		</div>
	</div>
</template>

<script>
import { getParameter } from '../../assets/js/util'
export default {
  name: 'Percialization',
  data () {
    return {
      isHide: true
    }
  },
  beforeMount () {
    const chn = getParameter('chn')
    const pattern = /baidu/i
    if (chn && pattern.test(chn)) {
      this.isHide = false
    }
  },
  methods: {
    close () {
      this.isHide = true
    }
  }
}
</script>

<style lang="scss" scoped>
.pecialization_layer {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  min-width: 1190px;
  height: 130px;
  border-radius: 59px 59px 0px 0px;
  background: #B1A8FF;
  z-index: 100;
  .pecialization_center {
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 934px;
    padding: 0 116px 0 257px;
    background: url(//sslstatic.xiaoyusan.com/img/pc/images/index/pecialization-banner.76f1bb005c0b99db.png) no-repeat center;
    background-size: 934px;
    .pecialization_pic {
      position:absolute;
      top: -78px;
      left: 60px;
      display: block;
      width: 163px;
      height: 208px;
    }
    .pecialization_center_text  {
      font-size: 20px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 27px;
      text-shadow: 0px 2px 18px rgba(31,0,255,0.28);
      .pecialization_center_text_line2 {
        font-size: 24px;
      }
    }
    .pecialization_btn  {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 110px;
      height: 36px;
      font-size: 18px;
      font-weight: bold;
      text-shadow: 0px 0px 4px rgba(226,114,0,0.59);
      color: #FFFFFF;
      background: linear-gradient(270deg, #FFC06D 0%, #FFDF7F 100%);
      border-radius: 23px;
      border: 1px solid #FFF8EE;
    }
    .pecialization_close {
      position: absolute;
      top: 10px;
      right: 29px;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
}
.hide {
  display: none;
}
</style>