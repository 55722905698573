var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "addition" }, [
    _c(
      "div",
      {
        class: [
          "hlyj",
          "addition-uniform",
          _vm.activeItem === "hlyj" ? "active" : "",
        ],
        on: {
          mouseover: function (e) {
            return _vm.mouseover(e, "hlyj")
          },
          mouseout: _vm.mouseout,
          click: _vm.consult,
        },
      },
      [_vm._v(" 咨询 ")]
    ),
    _c(
      "div",
      {
        ref: "scrollTop",
        class: [
          "scrollTop",
          "addition-uniform",
          _vm.activeItem === "scrollTop" ? "active" : "",
        ],
        on: {
          mouseover: function (e) {
            return _vm.mouseover(e, "scrollTop")
          },
          mouseout: _vm.mouseout,
          click: _vm.backToTop,
        },
      },
      [_vm._v(" 顶部 ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }