<template>
  <div style="min-height:49px;width:800px;z-index:99;background: #fff">
    <div ref="slideNav" id="slideNav" :class="['tab', isFixed ? 'fixed' : '']">
      <a
        v-for="item in classify" :key="item.class"
        :style="item.class == 10 ? 'width: 120px' : ''"
        :class="['fl', activeIndex == item.class ? 'active' : '']"
        @click="clickTab(item.class)"
      >
        <p class="tab_icon" :style="(classMap[item.class] && classMap[item.class].style) || ''"><i :class="(classMap[item.class] && classMap[item.class].icon) || ''"></i>{{ item.text }}</p>
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Tab',
  data () {
    return {
      activeIndex: 0,
      classMap: {
        0:{
          style: '',
          icon: 'icon_all'
        },
        1:{
          style: 'padding-left:26px',
          icon: 'icon_child'
        },
        2:{
          style: 'padding-left:25px',
          icon: 'icon_adult'
        },
        3:{
          style: 'padding-left:24px',
          icon: 'icon_elder'
        },
        10:{
          style: 'padding-left:28px',
          icon: 'icon_group'
        },
        8:{
          style: 'padding-left:31px',
          icon: 'icon_family'
        },
        5:{
          style: 'padding-left:31px',
          icon: 'icon_tourism'
        },
        7:{
          style: 'padding-left:31px',
          icon: 'icon_group'
        }
      },
      isFixed: false,
      navBoundary: 0
    }
  },
  computed: {
    ...mapState(['item']),
    classify () {
      return (this.item && this.item.itemClass) || []
    }
  },
  mounted () {
    this.navBoundary = this.getNavBoundary()
    this.navFixedParams = this.navFixed.bind(this)
    document.addEventListener('scroll', this.navFixedParams)
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.navFixedParams)
  },
  methods: {
    getNavBoundary () {
      const nav = this.$refs.slideNav
      const navTop = nav.offsetTop
      const navOffset = parseFloat(getComputedStyle(nav).marginTop.replace(/auto/, 0))
      return navTop - navOffset
    },
    clickTab (index) {      
      this.activeIndex = Number(index)
      if (this.isFixed) {
        this.scroll()
      }
      this.$emit('tabchange', this.activeIndex)
    },
    scroll () {
      const ua = navigator.userAgent.toLowerCase()
      const isIE = ua.indexOf('msie') > -1
      if (isIE) {
        if (document.documentElement.scrollTop != this.navBoundary) {
          window.scrollTo(0, this.navBoundary)
        }
      } else {
        if (document.body.scrollTop != this.navBoundary) {
          window.scrollTo(0, this.navBoundary)
        }
      }
    },
    navFixed () {
      const y = document.documentElement.scrollTop
      if (y >= this.navBoundary) {
        this.isFixed = true
      } else {
        this.isFixed = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tab {
  font-size: 16px;
  background-color: #fff;
  &.fixed {
    position: fixed;
    top: 0;
  }
  a {
    position:relative;
    color:#969696;
    height:48px;
    line-height: 48px;
    width:97px;
    cursor: pointer;
    font-size: 18px;
    text-align: center;
    &.active {
      background-color:#8c7fee;
      color:#fff;
      &:after {
        content:"";
        position:absolute;
        left:50%;
        margin-left:-8px;
        bottom:-8px;
        display: block;
        width: 0;
        height:0;
        border-width: 8px 8px 0 8px;
        border-style:solid;
        pointer-events:none;
        border-color:transparent;
        border-top-color:#8c7fee;
      }
    }
  }
}
.tab_icon {
  .icon_all { background-position: 0 -2px; }
  .icon_elder { background-position:0 -38px; }
  .icon_child { background-position:0 -78px }
  .icon_adult { background-position:0 -116px; }
  .icon_tourism { background-position:0 -154px; }
  .icon_motion { background-position:0 -194px; }
  .icon_family { background-position:0 -270px; }
  .icon_group { background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAWCAYAAAAvg9c4AAACgElEQVQ4jYWVXWjPURjHP9MYIRyFUltDLnDlpZGXVi6URMnydrWSWpnEERZOJxcyxw3ahZRcYK1RNBculHltcmcohVJqKcfKEvLWs57f+vvtnDw3v875fc/nPOc8L6eKjAUX1wG7gAZgJjAO+AV8AJ4CF603d1Krk9Dg4kGgHRgCngBvgW/AeGAOsAKYBLRZb06W11dnHG0DngNrrDeDiU2nAvdVNwo6JgMdC3xOAcV0fjB30hz0sngZXNyf+hlcbAFWq+7/0ODidKBWh+szmy7Tb63q89DgYh3QB2wAOoDNGehe/S+6Pl03YlUVwBkKnA00W2+uZICVTuwELmmaNVhvPlJAg4vi8V2gEdhhvbmm8xOBJqDLevM1uDgB2Ap0W2+GVLMNEP09YK315ndx/FYFthdAteXqSY+e5LaOVxUC600ncFrXtw57GlycArwDPgGLrDffS0f0wPGK5D9lvTlc0ki1vQAkaPXiaTMwDTiWAF5VIAoUO6TzI2a9+QEcVU5ztd6ZeNmdiMV1QC5/rka6B3gDPMhohdMkni4Beq03PxPCfg1Af2n8uCzU9b3CE09rtHGkTI65uGK+uMsuzYKyCadGoAPAvAx0C1Cn0T4h9w48BF5l9MIZkOhLkkuu1Vtv3qeUwcWNwE1gk/XmVkZTq1nUKXd6Vsu1Q4sgZVIYLfpNAcdo2Q7zioo6B+yRXYDd1psvGXgKOBm4oKc9b71pLZr0Ps1DeT4ag4tnpPSsN1LTOZj0iO3AAWCWPC/K+bfJBhelK0knn69Tr7VSJFeLipJyXVjSHLHe3Cg4ozp3cFHmVkpQgKXAAkCeDylFqRzp+C+BZxq8R9abPyMA4C8/5NOdKb65EAAAAABJRU5ErkJggg==") no-repeat center; }
  .icon_long { background-position:0 -312px; }
}
.active {
  .tab_icon {
    .icon_all { background-position:-45px 0; }
    .icon_elder { background-position:-45px -38px; }
    .icon_child { background-position:-45px -78px; }
    .icon_adult { background-position:-45px -116px; }
    .icon_tourism { background-position:-45px -154px; }
    .icon_motion { background-position:-45px -194px; }
    .icon_family { background-position:-45px -270px; }
    .icon_group { background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAWCAYAAAAvg9c4AAABl0lEQVQ4jaWVMUtcQRRGz1sTIQRxQRArtQukMcGAhVUqwUYQrIT8BW1E8jssUsRAUguCjem0sgtELARBMEljEzSFTTThyI3zYNE345N8sDzu7HfPm3vnzi45qTPqhvpD/e2N/qjf0/pMNrlJ6ort9LYpv8pAz4Fui/dfVFU1cHuxkzE/bllU46Zy0E8toY2+O1B1CBhtCR1N/rzUMfW45SHVCv9YI1QdVk8eCKwVecO3gR11t8Ecc7mt/k1xPD+rVw3eyO/0QpcyOzhQK3UxXYDFFH/N+Jdq4KB6Vijtg9qnjiTgu4I3OIMBXS6YVtVuXVZqUzet57T8CFgoDMQs8AT4BnwE3gDjwOtCzkJAJwuGZ7FBYD/FL4CX6ZPTJIUyQrOZ8csd7D+F4bTw/U91X11PsPUUXxRyTuMAdgqlxBWcSG2o2xHx00LOTrx96p4WmC7BZnrep6m6R2stzG201tv4GO73/wmM/L6mU51Xjx4IC/98L+fOL3dcRWAamANeAc/TX0s/cAn8Ag6BL8AWsFdVVczyjYBre0S6oMndA8wAAAAASUVORK5CYII=") no-repeat center; }
    .icon_long { background-position:-45px -312px; }
  }
}
</style>