var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        "min-height": "49px",
        width: "800px",
        "z-index": "99",
        background: "#fff",
      },
    },
    [
      _c(
        "div",
        {
          ref: "slideNav",
          class: ["tab", _vm.isFixed ? "fixed" : ""],
          attrs: { id: "slideNav" },
        },
        _vm._l(_vm.classify, function (item) {
          return _c(
            "a",
            {
              key: item.class,
              class: ["fl", _vm.activeIndex == item.class ? "active" : ""],
              style: item.class == 10 ? "width: 120px" : "",
              on: {
                click: function ($event) {
                  return _vm.clickTab(item.class)
                },
              },
            },
            [
              _c(
                "p",
                {
                  staticClass: "tab_icon",
                  style:
                    (_vm.classMap[item.class] &&
                      _vm.classMap[item.class].style) ||
                    "",
                },
                [
                  _c("i", {
                    class:
                      (_vm.classMap[item.class] &&
                        _vm.classMap[item.class].icon) ||
                      "",
                  }),
                  _vm._v(_vm._s(item.text)),
                ]
              ),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }