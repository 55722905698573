var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content_right fr", attrs: { id: "sidebarParent" } },
    [
      _c("know-ledge"),
      _c("feature"),
      _c("honour"),
      _c("report"),
      _c("partners"),
      _c("company"),
      _c("service"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }