<template>
  <div class="section">
    <h3 class="section_tit"><hr>媒体报道<a href="/news" target="_blank" class="section_more">查看更多</a></h3>
    <div class="interview">
      <div class="interview_bd">
        <ul class="interview_bd_ul">
          <li v-for="(interview, idx) in interviewList" :key="idx" class="interview_bd_li" :style="activeIdx !== idx ? `display:none;opacity:${curOpacity}` : `opacity:${curOpacity}`">
            <a :href="interview.href" class="interview_link" target="_blank">
              <img :src="interview.img" alt="" class="interview_img"/>
              <img :src="g_global_cdn + '/pc/images/index/interview/icon_ship.png'" alt="" class="interview_ship"/>
            </a>
          </li>
        </ul>
      </div>
      <div class="interview_hd clearfix">
        <ul class="interview_hd_ul">
          <li
            v-for="(interview, idx) in interviewList"
            :key="idx"
            :class="['interview_label', 'icon', 'icon3_1', activeIdx === idx ? 'on' : '']"
            @click="active(idx)"
          ></li>
        </ul>
      </div>
    </div>
    <div class="report">
      <a v-for="(article, index) in mediaReports" :key="index" :href="article.url" target="_blank" class="report_item clearfix">
        <div class="report_img fl">
          <img class="lazy2" v-lazy="article.img" :alt="article.title">
        </div>
        <p class="reportt_txt fr ">{{ article.title }}</p>
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Report',
  props: {
    reports: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      interviewList: [
        {
          href: 'https://www.xiaoyusan.com/shk/wkpage/article/index.html?articleid=300007266',
          img: 'https://sslstatic.xiaoyusan.com/img/pc/index/reportIndex.a8fced53e5a77d5f.png'
        },
        {
          href: 'https://www.xiaoyusan.com/shk/wkpage/article/index.html?articleid=300007282',
          img: 'https://sslstatic.xiaoyusan.com/img/pc/index/award.07a63ff356d50170.png'
        }
      ],
      activeIdx: 0,
      curOpacity: 1
    }
  },
  computed: {
    ...mapState(['mediaReports'])
  },
  methods: {
    active (idx) {
      this.animation(idx)
    },
    animation (idx) {
      const fadeOut = setInterval(() => {
        this.curOpacity -= 0.1
        if (this.curOpacity <= 0) {
          clearInterval(fadeOut)
          this.activeIdx = idx
          const fadeIn = setInterval(() => {
            this.curOpacity += 0.1
            if (this.curOpacity >= 1) {
              clearInterval(fadeIn)
            }
          }, 10);
        }
      }, 10);
    }
  }
}
</script>

<style lang="scss" scoped>
.section {
  border-bottom:1px solid #e6e6e6;
  .section_tit {
    position:relative;
    font-size:20px;
    color:#3c3c3c;
    font-weight: 400;
    padding:30px 8px 20px;
    text-align: left;
    clear: both;
    hr {
      position:absolute;
      left:0;
      top:37px;
      width: 3px;
      border:none;
      height:18px;
      background-color:#8c7fee;
      font-size: 0
    }
    .section_more {
      position: absolute;
      right:0;
      top:38px;
      font-size:13px;
      color:#8c7fee;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &:last-child {
    border-bottom:none;
  }

  .interview {
    width: 320px;
    position:relative;
    margin-bottom: 20px;
    .interview_hd {
      z-index: 2;
      text-align: center;
      padding-top: 1rem;
      .interview_hd_ul {
        overflow:hidden;
        zoom:1;
        float:left;
        width: 100%;
        .interview_label {
          overflow: hidden;
          display: inline-block;
          margin-right: 8px;
          width: 15px;
          height: 15px;
          cursor: pointer;
        }
      }
    }
    .interview_bd {
      height: 217px;
      overflow:hidden;
      .interview_bd_ul {
        overflow:hidden;
        zoom:1;
        .interview_bd_li {
          float:left;
          _display:inline;
          overflow:hidden;
          .interview_link {
            display:block;
            position: relative;
            width: 320px;
            height: 217px;
            .interview_img {
              width: 320px;
              height: 217px;
              object-fit: cover;
            }
            .interview_ship {
              position: absolute;
              top:50%;
              left:50%;
              margin-left:-32px;
              margin-top:-53px;
              width:64px;
              height: 64px;
            }
          }
        }
      }
    }
  }

  .report {
    padding-bottom:8px;
    .report_item {
      display: block;
      margin-bottom:25px;
      &:hover {
        color:#8c7fee
      }
      .report_img {
        width: 90px;
        height:60px;
        border:1px solid #e6e6e6;
        border-radius:5px;
        text-align: center;
        line-height: 60px;
        overflow: hidden;
        img {
          max-width: 90px;
          max-height:60px;
          border-radius: 5px;
        }
      }
      .reportt_txt {
        color:#3c3c3c;
        font-size:15px;
        width: 220px;
        padding-left:9px;
        padding:8px 0;
        &:hover {
          color:#8c7fee;
        }
      }
    }
  }
}
</style>