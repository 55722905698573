var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section" }, [
      _c("h3", { staticClass: "section_tit" }, [_c("hr"), _vm._v("平台特色")]),
      _c("div", { staticClass: "feature clearfix" }, [
        _c("div", { staticClass: "feature_item icon icon2_1" }, [
          _c("a", [_vm._v("数据鉴别")]),
          _c("p", { staticClass: "feature_layer" }, [
            _c("span", { staticStyle: { "padding-right": "5px" } }, [
              _vm._v("●"),
            ]),
            _vm._v("依托大数据"),
            _c("br"),
            _c("span", { staticStyle: { "padding-right": "5px" } }, [
              _vm._v("●"),
            ]),
            _vm._v("精确细分人群"),
            _c("br"),
            _c("span", { staticStyle: { "padding-right": "5px" } }, [
              _vm._v("●"),
            ]),
            _vm._v("为用户量身打造"),
          ]),
        ]),
        _c("div", { staticClass: "feature_item icon icon2_2" }, [
          _c("a", [_vm._v("独家定制")]),
          _c("p", { staticClass: "feature_layer" }, [
            _c("span", { staticStyle: { "padding-right": "5px" } }, [
              _vm._v("●"),
            ]),
            _vm._v("专业保险精算师团队"),
            _c("br"),
            _c("span", { staticStyle: { "padding-right": "5px" } }, [
              _vm._v("●"),
            ]),
            _vm._v("保险产品独家设计"),
            _c("br"),
            _c("span", { staticStyle: { "padding-right": "5px" } }, [
              _vm._v("●"),
            ]),
            _vm._v("专属保障 独一无二"),
          ]),
        ]),
        _c("div", { staticClass: "feature_item icon icon2_3" }, [
          _c("a", [_vm._v("行业底价")]),
          _c("p", { staticClass: "feature_layer" }, [
            _c("span", { staticStyle: { "padding-right": "5px" } }, [
              _vm._v("●"),
            ]),
            _vm._v("无中间环节差价"),
            _c("br"),
            _c("span", { staticStyle: { "padding-right": "5px" } }, [
              _vm._v("●"),
            ]),
            _vm._v("超高性价比"),
            _c("br"),
            _c("span", { staticStyle: { "padding-right": "5px" } }, [
              _vm._v("●"),
            ]),
            _vm._v("特定人群价格更优"),
            _c("br"),
          ]),
        ]),
        _c("div", { staticClass: "feature_item icon icon2_4" }, [
          _c("a", [_vm._v("嗖嗖理赔")]),
          _c("p", { staticClass: "feature_layer" }, [
            _c("span", { staticStyle: { "padding-right": "5px" } }, [
              _vm._v("●"),
            ]),
            _vm._v("拍照上传，极速闪赔"),
            _c("br"),
            _c("span", { staticStyle: { "padding-right": "5px" } }, [
              _vm._v("●"),
            ]),
            _vm._v("大案协助，重疾绿通"),
            _c("br"),
            _c("span", { staticStyle: { "padding-right": "5px" } }, [
              _vm._v("●"),
            ]),
            _vm._v("更多特色增值服务"),
            _c("br"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }