<template>
  <div class="pc_page">
    <banner />
    <index-content />
    <download />
    <percialization />
  </div>
</template>
<script>
import Banner from './components/banner.vue'
import IndexContent from './components/content/index.vue'
import Download from './components/download.vue'
import Percialization from './components/percialization.vue'
import { mapActions } from 'vuex'
export default {
  name: "Index",
  components: {
    Banner,
    IndexContent,
    Download,
    Percialization
  },
  data() {
    return {}
  },
  methods: {
    ...mapActions(['getFirstScreen', 'getPcIndexData'])
  },
  async asyncData() {
    await this.getFirstScreen({
      vueInstance: this,
      postData: this.$root.$$params
    })
  },
  async afterAsyncData() {
    await this.getPcIndexData({
      vueInstance: this
    })
  }
}
</script>
