<template>
  <div class="total-list">
    <list-item
      v-for="(item, idx) in itemList"
      :key="`${item.productId}${idx}`"
      :item="item"
      :activeIndex="activeIndex"
    />
  </div>
</template>

<script>
import ListItem from './item.vue'
import { mapState } from 'vuex'
export default {
  name: 'List',
  components: {
    ListItem
  },
  props: {
    tabIndex: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      activeIndex: 0
    }
  },
  computed: {
    ...mapState(['item']),
    itemList () {
      return (this.item && this.item.itemList) || []
    },
    curItemList () {
      if (this.activeIndex === 0) {
        return this.itemList
      }
      return this.itemList.filter(item => item.class.includes(String(this.activeIndex)))
    },
    classify () {
      return (this.item && this.item.itemClass) || []
    }
  },
  watch: {
    tabIndex (val) {
      this.activeIndex = val
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  display: none;
  &.active {
    display: block;
  }
}
</style>