<template>
  <div class="section">
    <h3 class="section_tit"><hr>平台特色</h3>
    <div class="feature clearfix">
      <div class="feature_item icon icon2_1">
        <a>数据鉴别</a>
        <p class="feature_layer"><span style="padding-right:5px">●</span>依托大数据<br><span style="padding-right:5px">●</span>精确细分人群<br><span style="padding-right:5px">●</span>为用户量身打造</p>
      </div>
      <div class="feature_item icon icon2_2">
        <a>独家定制</a>
        <p class="feature_layer"><span style="padding-right:5px">●</span>专业保险精算师团队<br><span style="padding-right:5px">●</span>保险产品独家设计<br><span style="padding-right:5px">●</span>专属保障 独一无二</p>
      </div>
      <div class="feature_item icon icon2_3">
        <a>行业底价</a>
        <p class="feature_layer"><span style="padding-right:5px">●</span>无中间环节差价<br><span style="padding-right:5px">●</span>超高性价比<br><span style="padding-right:5px">●</span>特定人群价格更优<br></p>
      </div>
      <div class="feature_item icon icon2_4">
        <a>嗖嗖理赔</a>
        <p class="feature_layer"><span style="padding-right:5px">●</span>拍照上传，极速闪赔<br><span style="padding-right:5px">●</span>大案协助，重疾绿通<br><span style="padding-right:5px">●</span>更多特色增值服务<br></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Feature'
}
</script>

<style lang="scss" scoped>
.section {
  border-bottom:1px solid #e6e6e6;
  .section_tit {
    position:relative;
    font-size:20px;
    color:#3c3c3c;
    font-weight: 400;
    padding:30px 8px 20px;
    text-align: left;
    clear: both;
    hr {
      position:absolute;
      left:0;
      top:37px;
      width: 3px;
      border:none;
      height:18px;
      background-color:#8c7fee;
      font-size: 0
    }
    .section_more {
      position: absolute;
      right:0;
      top:38px;
      font-size:13px;
      color:#8c7fee;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &:last-child {
    border-bottom:none;
  }
  
  .feature {
    font-size:20px;
    padding-bottom:30px;
    .feature_item {
      position:relative;
      float:left;
      padding-left:50px;
      margin:25px 14px;
      cursor: pointer;
      a {
        display:inline-block;
        color:#8c7fee;
        height:44px;
        line-height: 44px;
        background-color: #fff;
      }
      .feature_layer {
        display: none;
        position:absolute;
        top:50%;
        left:50%;
        margin-left:-77.5px;
        margin-top:-44.5px;
        background-color: #8c7fee;
        color:#fff;
        font-size:14px;
        width:141px;
        height:65px;
        text-align: left;
        padding:10px 7px;
      }
      &:hover {
        &>.feature_layer {
          display: block;
        }
      }
    }
  }
}
</style>