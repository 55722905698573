<template>
  <div class="content clearfix" id="wrapper">
    <product-list />
    <right-bar />
    <back-to-top />
  </div>
</template>

<script>
import ProductList from './productList/index.vue'
import RightBar from './rightBar/index.vue'
import BackToTop from './backToTop.vue'
export default {
  name: 'Content',
  components: {
    ProductList,
    RightBar,
    BackToTop
  }
}
</script>

<style>

</style>