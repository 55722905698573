<template>
  <div class="section">
    <h3 class="section_tit" style="padding-bottom:15px"><hr>公司荣誉</h3>
    <div class="honor">
      <ul ref="list" :class="['honor_ul', animate ? 'anim' : '']" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
        <li class="honor_li" v-for="item in list" :key="item.href">
          <a :href="item.href" class="honor_link" target="_blank">
            <span class="honor_icon"></span>{{ item.title }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Honour',
  data () {
    return {
      animate: false,
      list: [
        {
          href: 'https://www.xiaoyusan.com/shk/wkpage/article/index.html?articleid=300008323',
          title: '小雨伞保险经纪荣膺2024新浪金麒麟保险行业年度卓越保险中介机构'
        },
        {
          href: 'https://www.xiaoyusan.com/shk/wkpage/article/index.html?articleid=300007282',
          title: '光耀入选2024《财富》中国40岁以下最具潜力商界精英 '
        },
        {
          href: 'https://www.xiaoyusan.com/shk/wkpage/article/index.html?articleid=300005551',
          title: '小雨伞保险经纪凭借以客户为中心服务理念入选年度影响力赔案'
        },
        {
          href: 'https://www.xiaoyusan.com/shk/wkpage/article/index.html?articleid=300004987',
          title: '小雨伞荣获零壹智库年度保险科技20强，以数字技术赋能保险消费的全链路'
        },
        {
          href: 'https://www.xiaoyusan.com/shk/wkpage/article/index.html?articleid=300004408',
          title: '小雨伞保险经纪“金医保”百万医疗险荣获保险产品创新奖'
        },
      ]
    }
  },
  methods: {
    mouseEnter () {
      clearInterval(this.timer)
    },
    mouseLeave () {
      this.timer = setInterval(this.scroll, 2500)
    },
    scroll () {
      let list = this.$refs.list
      list.style.marginTop = '-31px'
      this.animate = !this.animate
      setTimeout(() => {
        this.list.push(this.list[0])
        this.list.shift()
        list.style.marginTop = '0px'
        this.animate = !this.animate
      }, 500)
    }
  },
  mounted () {
    this.timer = setInterval(this.scroll, 2500)
  }
}
</script>

<style lang="scss" scoped>
.section {
  border-bottom:1px solid #e6e6e6;
  .section_tit {
    position:relative;
    font-size:20px;
    color:#3c3c3c;
    font-weight: 400;
    padding:30px 8px 20px;
    text-align: left;
    clear: both;
    hr {
      position:absolute;
      left:0;
      top:37px;
      width: 3px;
      border:none;
      height:18px;
      background-color:#8c7fee;
      font-size: 0
    }
    .section_more {
      position: absolute;
      right:0;
      top:38px;
      font-size:13px;
      color:#8c7fee;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &:last-child {
    border-bottom:none;
  }
  
  .honor {
    margin-bottom: 20px;
    height: 93px;
    width: 320px;
    overflow:hidden;
    .honor_li {
      padding:5px 0;
      .honor_link {
        display:block;
        font-size:14px;
        color:#646464;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        &:hover {
          color:#8c7fee;
        }
        .honor_icon {
          display:inline-block;
          background:url("data:image/jpg;base64,/9j/4QAYRXhpZgAASUkqAAgAAAAAAAAAAAAAAP/sABFEdWNreQABAAQAAAA8AAD/4QMraHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLwA8P3hwYWNrZXQgYmVnaW49Iu+7vyIgaWQ9Ilc1TTBNcENlaGlIenJlU3pOVGN6a2M5ZCI/PiA8eDp4bXBtZXRhIHhtbG5zOng9ImFkb2JlOm5zOm1ldGEvIiB4OnhtcHRrPSJBZG9iZSBYTVAgQ29yZSA1LjMtYzAxMSA2Ni4xNDU2NjEsIDIwMTIvMDIvMDYtMTQ6NTY6MjcgICAgICAgICI+IDxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+IDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCBDUzYgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjlEQ0UwQUM4Q0JGQzExRTY5RDhCRTdCMDg2M0IyMzdBIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjlEQ0UwQUM5Q0JGQzExRTY5RDhCRTdCMDg2M0IyMzdBIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OURDRTBBQzZDQkZDMTFFNjlEOEJFN0IwODYzQjIzN0EiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OURDRTBBQzdDQkZDMTFFNjlEOEJFN0IwODYzQjIzN0EiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7/7gAOQWRvYmUAZMAAAAAB/9sAhAAGBAQEBQQGBQUGCQYFBgkLCAYGCAsMCgoLCgoMEAwMDAwMDBAMDg8QDw4MExMUFBMTHBsbGxwfHx8fHx8fHx8fAQcHBw0MDRgQEBgaFREVGh8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx//wAARCAAVABMDAREAAhEBAxEB/8QAfwAAAgMAAAAAAAAAAAAAAAAABgcBBQgBAAMBAQAAAAAAAAAAAAAAAAMEBQECEAABAgQEBAILAAAAAAAAAAABAgMREgQFABMUBiExIhUyFlHhQiNTNERUBxcYEQABAwIEBgMAAAAAAAAAAAABABECIQPwURIEMUFhcYEToSIF/9oADAMBAAIRAxEAPwDRe8L/AFVjtusZQ3lpCy68/ENIlQVJCiCmWciUE8I4DfnKIeIRLUBIsUMfs9SnmGmaqifW+uRtsNuJWoZZc6BmKKlGEoSOZOJO3/TuXJmOkU746Jqe0EQ9UZa67/Y/R5/jHzPwPXixqlly+ckowzUbm3FYtvWZ+6Xyobprc3Kh1bsJSXCEJTA85icdSkwdcgOWSt2huH8Ybf3LX3E1Vvp+/VTabUtuqp3y2HejLShC1llKlEEy8I84AYl7O+DckNJDmn1anXym7wkYCvDHlObFZJqk3l5Q7C/5u0nZYpztdLlTR6ITe1Hww4+jGFmqtD8kubV/M3cabSdq1U402fmSTx6YZ/u4x5RwGHrejOjT9jV4Jw4OgL//2Q==");
          width: 19px;
          height: 21px;
          vertical-align: -5px;
          margin-right: 5px;
        }
      }
    }
  }
}
.anim {
  transition: all .8s
}
</style>