<template>
  <div class="addition">
    <div
      :class="['hlyj', 'addition-uniform', activeItem === 'hlyj' ? 'active' : '']"
      @mouseover="e => mouseover(e, 'hlyj')"
      @mouseout="mouseout"
      @click="consult"
    >
      咨询
    </div>
    <div
      ref="scrollTop"
      :class="['scrollTop', 'addition-uniform', activeItem === 'scrollTop' ? 'active' : '']"
      @mouseover="e => mouseover(e, 'scrollTop')"
      @mouseout="mouseout"
      @click="backToTop"
    >
      顶部
    </div>
  </div>
</template>

<script>
import { fadeIn, fadeOut } from '../../../assets/js/util'
export default {
  name: 'BackToTop',
  data () {
    return {
      activeItem: ''
    }
  },
  mounted () {
    document.addEventListener('scroll', this.scrollTopAnimate)
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.scrollTopAnimate)
  },
  methods: {
    mouseover (e, type) {
      this.activeItem = type
    },
    mouseout () {
      this.activeItem = ''
    },
    consult () {
      window.XYSConsultV3.genConsultUrl({ scene: 'shouye', target: 'iframe' }).then(url => {
        console.log(url)
      })
    },
    backToTop () {
      window.scrollTo(0, 0)
    },
    scrollTopAnimate () {
      const y = document.documentElement.scrollTop
      if (y > 500) {
        fadeIn(this.$refs.scrollTop, 200)
      } else {
        fadeOut(this.$refs.scrollTop, 200)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.addition {
  position:fixed;
  overflow:hidden;
  left: 50%;
  margin-left: 610px;
  bottom:78px;
  width:54px;
  height:114px;
  font-size: 12px;
  color: #b3b3b3;
  .addition-uniform {
    box-sizing: border-box;
    text-align:center;
    background-repeat: no-repeat;
    line-height: 1;
    border:1px solid #e5e5e5;
    cursor: pointer;
    &.active {
      position:relative;
      color:#fff;
    }
  }
  .hlyj {
    height: 60px;
    padding-top: 41px;
    background-color: #fff;
    background-image: url(//sslstatic.xiaoyusan.com/pc/images/index/consult_icon.png);
    background-position: center 10px;
    &.active {
      &::before {
        content:"在线咨询";
        white-space: pre-wrap;
        box-sizing: border-box;
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        font-size: 14px;
        line-height: 18px;
        padding: 0 10px;
        padding-top:11px;
        background-color:#8c7fee;
        background-image:none;
        text-align:center;
      }
    }
  }
  .scrollTop {
    display:none;
    opacity: 0.1;
    height: 54px;
    padding-top: 32px;
    border-top: none;
    background-color: #fff;
    background-image: url(//sslstatic.xiaoyusan.com/pc/images/index/upper_arrow_icon_1.png);
    background-position: center 14px;
    &.active {
      background-color:#8c7fee;
      background-image: url(//sslstatic.xiaoyusan.com/pc/images/index/upper_arrow_icon_2.png);
    }
  }
}
</style>