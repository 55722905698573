<template>
  <div class="company_container">
    <h3 class="section_tit"><hr>合作的保险公司<a href="/static/html/intro.html?page=suggest" target="_blank" class="section_more" style="display:none;">查看更多</a></h3>
    <client-only>
      <swiper ref="bannerSwiper" class="my-swiper" :options="swiperOption">
        <swiper-slide v-for="(pageItem, pageIndex) of list" :key="`company_${pageIndex}`">
          <div class="swiper_item">
            <a href="javascript:;" v-for="(item, index) of pageItem.list" :key="`swiper_item_${pageIndex}_${index}`">
              <img v-lazy="`${g_global_cdn}/pc/images/index/company/${item}`"  alt="" />
            </a>
          </div>
        </swiper-slide>
      </swiper>
    </client-only>
    <section class="idots_list" v-if="list.length">
      <i
        v-for="(item, index) in list.length"
        :class="[index === curIndex ? 'active' : '', 'idots']"
        :key="`idots-${index}`"
        @click.stop="changeBanner(index)"
      ></i>
    </section>
    <section class="arrow_list">
      <div class="arrow_item prev" @click.stop="prev">
        <img src="../../../../assets/img/prev.png" alt="小雨伞保险" class="arrow_icon">
      </div>
      <div class="arrow_item next" @click.stop="next">
        <img src="../../../../assets/img/next.png" alt="小雨伞保险" class="arrow_icon">
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: 'Company',
  data () {
    return {
      list: [
        {
          page: 1,
          list: ['pingan.png', 'zgrenshou.png', 'zgrmcaichan.png', 'taipingyang.png', 'zgtaiping.png', 'taikang.png', 'yangguangrenshou.png', 'dajiayanglao.png', 'beijingrenshou.png' ]
        },
        {
          page: 2,
          list: ['hetairenshou.png', 'junlongrenshou.png', 'zhongan.png', 'fudeshengming.png', 'fuxingbaodexin.png', 'zhaoshangrenhe.png', 'zhongyingrenshou.png', 'zhongyirenshou.png', 'zhongyourenshou.png' ]
        }
      ],
      curIndex: 0,
      swiperOption: {
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        speed: 400,
        loop: false,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        setWrapperSize: true,
        on: {
          transitionEnd: () => {
            // old version
            this.curIndex = this.$refs.bannerSwiper.swiper && this.$refs.bannerSwiper.swiper.realIndex
          },
        },
        onTransitionEnd: () => {
          // new version
          this.curIndex = this.$refs.bannerSwiper.swiper && this.$refs.bannerSwiper.swiper.realIndex
        }
      }
    }
  },
  methods: {
    changeBanner(index) {
      this.curIndex = index
      this.$refs.bannerSwiper.swiper.slideTo(index, 300)
    },
    prev () {
      if (this.curIndex === 0) {
        return false
      }
      this.changeBanner(this.curIndex - 1)
    },
    next () {
      if (this.curIndex === this.list.length - 1) {
        return false
      }
      this.changeBanner(this.curIndex + 1)
    }
  }
}
</script>
<style lang="scss" scoped>
.company_container {
  border-bottom:1px solid #e6e6e6;
  position: relative;
  .section_tit {
    position:relative;
    font-size:20px;
    color:#3c3c3c;
    font-weight: 400;
    padding:30px 8px 20px;
    text-align: left;
    clear: both;
    hr {
      position:absolute;
      left:0;
      top:37px;
      width: 3px;
      border:none;
      height:18px;
      background-color:#8c7fee;
      font-size: 0
    }
    .section_more {
      position: absolute;
      right:0;
      top:38px;
      font-size:13px;
      color:#8c7fee;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &:last-child {
    border-bottom:none;
  }
  .my-swiper {
    width: 100%;
    height: 290px;
    overflow: hidden;
    .swiper_item {
      width: 100%;
      height: 220px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      a {
        font-size: 0;
        display: inline-block;
        width: 100px;
        height: 50px;
        line-height: 60px;
        border: 1px solid #e6e6e6;
        box-sizing: border-box;
        border-radius: 5px;
        -webkit-border-radius:5px;
        margin-right: 10px;
        margin-bottom: 35px;
        overflow: hidden;
        text-align: center;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      a:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
  .idots_list {
    display: flex;
    position: absolute;
    left: 0;
    bottom: 33px;
    z-index: 3;
    .idots {
      display: inline-block;
      width: 6px;
      height: 6px;
      margin-right: 8px;
      background-color: #DDE0E9;
      border-radius: 6px;
      opacity: 0.8;
    }
    .idots.active {
      width: 18px;
      background-color: #8C7FEE;
      opacity: 1;
    }
  }
  .arrow_list {
    display: flex;
    position: absolute;
    right: 0;
    bottom: 20px;
    z-index: 3;
    .arrow_item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      background: #FFFFFF;
      border: 1px solid #E6E9EF;
      border-radius: 50%;
      .arrow_icon {
        width: 12px;
        height: 12px;
      }
    }
    .arrow_item.prev {
      margin-right: 10px;
      .arrow_icon {
        margin-right: 2px;
      }
    }
    .arrow_item.next {
      .arrow_icon {
        margin-left: 2px;
      }
    }
  }
}
</style>