var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "banner",
      on: { mouseenter: _vm.bannerEnter, mouseleave: _vm.bannerLeave },
    },
    [
      _vm._l(_vm.banner, function (b, idx) {
        return _c(
          "li",
          {
            key: idx,
            style:
              "background-color: " +
              b.color +
              ";opacity:" +
              _vm.curOpacity +
              ";" +
              (_vm.curIdx !== idx ? "display:none" : ""),
          },
          [
            _c("a", { attrs: { href: b.url, target: "_blank" } }, [
              _c("img", {
                staticClass: "image",
                attrs: { src: b.pic, alt: "小雨伞网上保险" },
              }),
            ]),
          ]
        )
      }),
      _c("div", {
        staticClass: "arrow left",
        on: {
          click: function ($event) {
            return _vm.changeActive(_vm.curIdx - 1)
          },
        },
      }),
      _c("div", {
        staticClass: "arrow right",
        on: {
          click: function ($event) {
            return _vm.changeActive(_vm.curIdx + 1)
          },
        },
      }),
      _c(
        "div",
        { staticClass: "indicator-list" },
        _vm._l(_vm.banner, function (b, idx) {
          return _c("li", {
            key: idx,
            class: ["indicator", _vm.curIdx === idx ? "active" : ""],
            on: {
              click: function ($event) {
                return _vm.changeActive(idx)
              },
            },
          })
        }),
        0
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }