<template>
  <div class="banner" @mouseenter="bannerEnter" @mouseleave="bannerLeave">
    <li
      v-for="(b, idx) in banner"
      :key="idx"
      :style="`background-color: ${b.color};opacity:${curOpacity};${curIdx !== idx ? 'display:none' : '' }`"
    >
      <a :href="b.url" target="_blank">
        <img class="image" :src="b.pic" alt="小雨伞网上保险">
      </a>
    </li>
    <div class="arrow left" @click="changeActive(curIdx - 1)"></div>
    <div class="arrow right" @click="changeActive(curIdx + 1)"></div>
    <div class="indicator-list">
      <li
        :class="['indicator', curIdx === idx ? 'active' : '']"
        v-for="(b, idx) in banner"
        :key="idx"
        @click="changeActive(idx)"
      ></li>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Banner',
  computed: {
    ...mapState(['banner'])
  },
  data () {
    return {
      curIdx: 0,
      curOpacity: 1,
      loopInterval: 0
    }
  },
  mounted () {
    this.loop()
  },
  methods: {
    loop () {
      this.loopInterval = setInterval(() => {
        this.changeActive(this.curIdx + 1)
      }, 4000)
    },
    bannerEnter () {
      clearInterval(this.loopInterval)
    },
    bannerLeave () {
      this.loop()
    },
    changeActive (idx) {
      let calcIdx = 0
      if (idx < 0) {
        calcIdx = this.banner.length - 1
      } else if (idx >= this.banner.length) {
        calcIdx = 0
      } else {
        calcIdx = idx
      }
      this.animation(calcIdx)
    },
    animation (idx) {
      const fadeOut = setInterval(() => {
        this.curOpacity -= 0.2
        if (this.curOpacity <= 0) {
          clearInterval(fadeOut)
          this.curIdx = idx
          const fadeIn = setInterval(() => {
            this.curOpacity += 0.2
            if (this.curOpacity >= 1) {
              clearInterval(fadeIn)
            }
          }, 10);
        }
      }, 10);
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  .image {
    display: block;
    width: 1190px;
    height: 300px;
    margin: auto;
  }
  li {
    list-style: none;
  }
  .arrow {
    position: absolute;
    top: 50%;
    margin-top: -25px;
    display: block;
    width: 32px;
    height: 40px;
    background: url(//sslstatic.xiaoyusan.com/pc/images/index/slider-arrow.png) -110px 5px no-repeat;
    opacity: 0.5;
    cursor: pointer;
    &.left {
      left: 1%;
    }
    &.right {
      right: 1%;
      background-position: 8px 5px;
    }
    &:hover {
      opacity: 1;
    }
  }
  .indicator-list {
    position: absolute;
    z-index: 2;
    left: 10px;
    right: 10px;
    bottom: 20px;
    text-align: center;
    cursor: pointer;
    .indicator {
      overflow: hidden;
      display: inline-block;
      width: 15px;
      height: 15px;
      cursor: pointer;
      background: url(//sslstatic.xiaoyusan.com/pc/images/index/icon.png) no-repeat;
      background-position: -18px -98px;
      transition: none;
      margin-right: 8px;
      &.active {
        background-position: -45px -98px;
      }
    }
  }
}
</style>