<template>
  <div class="content_right fr" id="sidebarParent">
    <know-ledge />
    <feature />
    <honour />
    <report />
    <partners />
    <company />
    <service />
  </div>
</template>

<script>
import KnowLedge from './knowledge.vue'
import Feature from './feature.vue'
import Honour from './honour.vue'
import Report from './report.vue'
import Partners from './partners.vue'
import Company from './company.vue'
import Service from './service.vue'
export default {
  name: 'RightBar',
  components: {
    KnowLedge,
    Feature,
    Honour,
    Report,
    Partners,
    Company,
    Service
  }
}
</script>

<style lang="scss" scoped>
.content_right{
  width: 320px;
  background:#fff;
  padding:0 20px;
}
</style>