var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "section" }, [
    _vm._m(0),
    _c("div", { staticClass: "section_tab" }, [
      _c("div", { staticClass: "section_tab_hd clearfix" }, [
        _c(
          "div",
          {
            class: ["section_tab_nav", _vm.activeIndex === 0 ? "active" : ""],
            on: {
              click: function ($event) {
                return _vm.active(0)
              },
            },
          },
          [_vm._v("重疾险"), _c("span", { staticClass: "arrow" })]
        ),
        _c(
          "div",
          {
            class: ["section_tab_nav", _vm.activeIndex === 1 ? "active" : ""],
            on: {
              click: function ($event) {
                return _vm.active(1)
              },
            },
          },
          [_vm._v("医疗险"), _c("span", { staticClass: "arrow" })]
        ),
        _c(
          "div",
          {
            class: ["section_tab_nav", _vm.activeIndex === 2 ? "active" : ""],
            on: {
              click: function ($event) {
                return _vm.active(2)
              },
            },
          },
          [_vm._v("意外险"), _c("span", { staticClass: "arrow" })]
        ),
        _c(
          "div",
          {
            class: ["section_tab_nav", _vm.activeIndex === 3 ? "active" : ""],
            on: {
              click: function ($event) {
                return _vm.active(3)
              },
            },
          },
          [_vm._v("寿险"), _c("span", { staticClass: "arrow" })]
        ),
      ]),
      _c("div", { staticClass: "section_tab_bd" }, [
        _c(
          "ul",
          {
            class: ["section_tab_list", _vm.activeIndex === 0 ? "active" : ""],
          },
          _vm._l(_vm.sick, function (art, index) {
            return _c("li", { key: index, staticClass: "section_tab_li" }, [
              _c(
                "a",
                {
                  staticClass: "section_tab_link",
                  attrs: { href: art.url, target: "_blank" },
                },
                [_vm._v(_vm._s(art.title))]
              ),
            ])
          }),
          0
        ),
        _c(
          "ul",
          {
            class: ["section_tab_list", _vm.activeIndex === 1 ? "active" : ""],
          },
          _vm._l(_vm.medical, function (art, index) {
            return _c("li", { key: index, staticClass: "section_tab_li" }, [
              _c(
                "a",
                {
                  staticClass: "section_tab_link",
                  attrs: { href: art.url, target: "_blank" },
                },
                [_vm._v(_vm._s(art.title))]
              ),
            ])
          }),
          0
        ),
        _c(
          "ul",
          {
            class: ["section_tab_list", _vm.activeIndex === 2 ? "active" : ""],
          },
          _vm._l(_vm.accident, function (art, index) {
            return _c("li", { key: index, staticClass: "section_tab_li" }, [
              _c(
                "a",
                {
                  staticClass: "section_tab_link",
                  attrs: { href: art.url, target: "_blank" },
                },
                [_vm._v(_vm._s(art.title))]
              ),
            ])
          }),
          0
        ),
        _c(
          "ul",
          {
            class: ["section_tab_list", _vm.activeIndex === 3 ? "active" : ""],
          },
          _vm._l(_vm.life, function (art, index) {
            return _c("li", { key: index, staticClass: "section_tab_li" }, [
              _c(
                "a",
                {
                  staticClass: "section_tab_link",
                  attrs: { href: art.url, target: "_blank" },
                },
                [_vm._v(_vm._s(art.title))]
              ),
            ])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "section_tit" }, [
      _c("hr"),
      _vm._v("保险知识"),
      _c(
        "a",
        {
          staticClass: "section_more",
          attrs: { href: "/bxzs", target: "_blank" },
        },
        [_vm._v("查看更多")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }