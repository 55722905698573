<template>
  <div :class="isHide ? 'hide' : ''">
    <div class="download_layer"></div>
    <div class="download">
      <img :src="g_global_cdn + '/pc/images/index/download_new_1.png?t=202204201111'" alt="" class="downpic">
      <img :src="g_global_cdn + '/img/icon_close1.png'" alt="" class="downclose" @click="close">
    </div>
  </div>
</template>

<script>
import { getCookie, getParameter } from '../../assets/js/util'
export default {
  name: 'Download',
  data () {
    return {
      isHide: true
    }
  },
  beforeMount () {
    const chn = getParameter('chn')
    const firstPc = getCookie('firstpc')
    const pattern = /baidu/i
    if (!(chn && pattern.test(chn))) {
      if (firstPc != 1) {
        this.isHide = false
      } else {
        this.isHide = true
      }
    }
  },
  methods: {
    close () {
      this.isHide = true
      const date = new Date()
      const expiresDays = 100000
      date.setTime(date.getTime() + expiresDays * 24 * 3600 * 1000)
      document.cookie = 'firstpc = 1;expires=' + date.toGMTString() + ';path = /'
    }
  }
}
</script>

<style lang="scss" scoped>
.hide {
  display: none;
}
.download_layer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  min-width: 1190px;
  height: 144px;
  background-color: #000;
  opacity: 0.7;
  filter: alpha(opacity=70);
  z-index: 100;
}
.download {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 1190px;
  height: 158px;
  margin-left: -595px;
  z-index: 101;
	.downpic {
    display: block;
    width: 1040px;
    margin: 0 auto;
  }
	.downclose {
    position: absolute;
    top: 24px;
    right: 40px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
}
</style>