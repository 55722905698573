var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.isHide ? "hide" : "" }, [
    _c("div", { staticClass: "download_layer" }),
    _c("div", { staticClass: "download" }, [
      _c("img", {
        staticClass: "downpic",
        attrs: {
          src:
            _vm.g_global_cdn +
            "/pc/images/index/download_new_1.png?t=202204201111",
          alt: "",
        },
      }),
      _c("img", {
        staticClass: "downclose",
        attrs: { src: _vm.g_global_cdn + "/img/icon_close1.png", alt: "" },
        on: { click: _vm.close },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }