var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "company_container" },
    [
      _vm._m(0),
      _c(
        "client-only",
        [
          _c(
            "swiper",
            {
              ref: "bannerSwiper",
              staticClass: "my-swiper",
              attrs: { options: _vm.swiperOption },
            },
            _vm._l(_vm.list, function (pageItem, pageIndex) {
              return _c("swiper-slide", { key: "company_" + pageIndex }, [
                _c(
                  "div",
                  { staticClass: "swiper_item" },
                  _vm._l(pageItem.list, function (item, index) {
                    return _c(
                      "a",
                      {
                        key: "swiper_item_" + pageIndex + "_" + index,
                        attrs: { href: "javascript:;" },
                      },
                      [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value:
                                _vm.g_global_cdn +
                                "/pc/images/index/company/" +
                                item,
                              expression:
                                "`${g_global_cdn}/pc/images/index/company/${item}`",
                            },
                          ],
                          attrs: { alt: "" },
                        }),
                      ]
                    )
                  }),
                  0
                ),
              ])
            }),
            1
          ),
        ],
        1
      ),
      _vm.list.length
        ? _c(
            "section",
            { staticClass: "idots_list" },
            _vm._l(_vm.list.length, function (item, index) {
              return _c("i", {
                key: "idots-" + index,
                class: [index === _vm.curIndex ? "active" : "", "idots"],
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.changeBanner(index)
                  },
                },
              })
            }),
            0
          )
        : _vm._e(),
      _c("section", { staticClass: "arrow_list" }, [
        _c(
          "div",
          {
            staticClass: "arrow_item prev",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.prev.apply(null, arguments)
              },
            },
          },
          [
            _c("img", {
              staticClass: "arrow_icon",
              attrs: {
                src: require("../../../../assets/img/prev.png"),
                alt: "小雨伞保险",
              },
            }),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "arrow_item next",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.next.apply(null, arguments)
              },
            },
          },
          [
            _c("img", {
              staticClass: "arrow_icon",
              attrs: {
                src: require("../../../../assets/img/next.png"),
                alt: "小雨伞保险",
              },
            }),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "section_tit" }, [
      _c("hr"),
      _vm._v("合作的保险公司"),
      _c(
        "a",
        {
          staticClass: "section_more",
          staticStyle: { display: "none" },
          attrs: {
            href: "/static/html/intro.html?page=suggest",
            target: "_blank",
          },
        },
        [_vm._v("查看更多")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }