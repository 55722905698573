var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "total-list" },
    _vm._l(_vm.itemList, function (item, idx) {
      return _c("list-item", {
        key: "" + item.productId + idx,
        attrs: { item: item, activeIndex: _vm.activeIndex },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }