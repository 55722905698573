var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["pecialization_layer", _vm.isHide ? "hide" : ""] },
    [
      _c("div", { staticClass: "pecialization_center" }, [
        _c("img", {
          staticClass: "pecialization_pic",
          attrs: {
            src:
              _vm.g_global_cdn +
              "/img/pc/images/index/pecialization-service.f4556a15eb9ce655.png",
            alt: "",
          },
        }),
        _vm._m(0),
        _c(
          "a",
          {
            staticClass: "pecialization_btn",
            attrs: {
              href: "https://www.xiaoyusan.com/promotion/page?pageid=a843f52dd4&chn=baidu-pz-jbp-xxsj-maddie-1v1zx-pc-xys-xys-wy-gw-1",
              target: "_blank",
            },
          },
          [_vm._v("立即预约")]
        ),
        _c("img", {
          staticClass: "pecialization_close",
          attrs: {
            src:
              _vm.g_global_cdn +
              "/img/pc/images/pecialization-close.410dbb1a3ba8428a.png",
            alt: "",
          },
          on: { click: _vm.close },
        }),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pecialization_center_text" }, [
      _c("p", { staticClass: "pecialization_center_text_line1" }, [
        _vm._v("不知道选哪款产品？"),
      ]),
      _c("p", { staticClass: "pecialization_center_text_line2" }, [
        _vm._v("专属顾问为您定制保障方案"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }