var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "container", staticClass: "container" }, [
    _c(
      "div",
      {
        ref: "service",
        class: _vm.panelFixed ? "fixed" : "",
        style: _vm.panelTop ? "top:" + _vm.panelTop + "px" : "",
        attrs: { id: "sidebar" },
      },
      [
        _c("div", { staticClass: "section" }, [
          _vm._m(0),
          _c("div", { staticClass: "answer" }, [
            _c("div", { staticClass: "answer_banner" }, [
              _c("img", {
                attrs: {
                  src:
                    _vm.g_global_cdn + "/pc/images/index/insurance_entrust.png",
                  alt: "",
                },
              }),
              _c("h2", [_vm._v("不知道该选哪款保险？")]),
              _c("h4", [_vm._v("专属顾问为您定制保障方案")]),
            ]),
            _vm._m(1),
            _c(
              "a",
              {
                staticClass: "btn-more",
                attrs: {
                  href: "/pay/actPay?id=1004&chn=cg-pc",
                  "data-track": "pcshouye",
                  target: "_blank",
                },
              },
              [_vm._v("立即预约")]
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "section_tit" }, [
      _c("hr"),
      _vm._v("保险问诊服务"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "answer_list" }, [
      _c("li", { staticClass: "an1" }, [
        _c("h4", [_vm._v("定制家庭整体保险方案")]),
        _c("p", [
          _vm._v("根据您和家人的保障需求和经济状况，制定"),
          _c("em", [_vm._v("家庭整体保险配置方案")]),
          _vm._v("。"),
        ]),
      ]),
      _c("li", { staticClass: "an2" }, [
        _c("h4", [_vm._v("市面产品方案对比")]),
        _c("p", [
          _vm._v("针对您关心的"),
          _c("em", [_vm._v("保险类型")]),
          _vm._v("，或者您想提供对比的产品，进行重点产品对比。"),
        ]),
      ]),
      _c("li", { staticClass: "an3" }, [
        _c("h4", [_vm._v("已有保单诊断")]),
        _c("p", [
          _vm._v("为您和家人进行已购买的"),
          _c("em", [_vm._v("保险诊断分析")]),
          _vm._v("。"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }