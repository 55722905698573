<template>
  <div class="content-left fl">
    <tab @tabchange="tabChange" />
    <list :tabIndex="activeIndex" />
  </div>
</template>

<script>
import Tab from './tabs.vue'
import List from './list.vue'
export default {
  name: 'ProductList',
  components: {
    Tab,
    List
  },
  data () {
    return {
      activeIndex: 0
    }
  },
  methods: {
    tabChange (index) {
      this.activeIndex = index
    }
  }
}
</script>

<style lang="scss" scoped>
.content-left{
  width: 800px;
}
</style>