var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "section" }, [
    _vm._m(0),
    _c("div", { staticClass: "interview" }, [
      _c("div", { staticClass: "interview_bd" }, [
        _c(
          "ul",
          { staticClass: "interview_bd_ul" },
          _vm._l(_vm.interviewList, function (interview, idx) {
            return _c(
              "li",
              {
                key: idx,
                staticClass: "interview_bd_li",
                style:
                  _vm.activeIdx !== idx
                    ? "display:none;opacity:" + _vm.curOpacity
                    : "opacity:" + _vm.curOpacity,
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "interview_link",
                    attrs: { href: interview.href, target: "_blank" },
                  },
                  [
                    _c("img", {
                      staticClass: "interview_img",
                      attrs: { src: interview.img, alt: "" },
                    }),
                    _c("img", {
                      staticClass: "interview_ship",
                      attrs: {
                        src:
                          _vm.g_global_cdn +
                          "/pc/images/index/interview/icon_ship.png",
                        alt: "",
                      },
                    }),
                  ]
                ),
              ]
            )
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "interview_hd clearfix" }, [
        _c(
          "ul",
          { staticClass: "interview_hd_ul" },
          _vm._l(_vm.interviewList, function (interview, idx) {
            return _c("li", {
              key: idx,
              class: [
                "interview_label",
                "icon",
                "icon3_1",
                _vm.activeIdx === idx ? "on" : "",
              ],
              on: {
                click: function ($event) {
                  return _vm.active(idx)
                },
              },
            })
          }),
          0
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "report" },
      _vm._l(_vm.mediaReports, function (article, index) {
        return _c(
          "a",
          {
            key: index,
            staticClass: "report_item clearfix",
            attrs: { href: article.url, target: "_blank" },
          },
          [
            _c("div", { staticClass: "report_img fl" }, [
              _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: article.img,
                    expression: "article.img",
                  },
                ],
                staticClass: "lazy2",
                attrs: { alt: article.title },
              }),
            ]),
            _c("p", { staticClass: "reportt_txt fr" }, [
              _vm._v(_vm._s(article.title)),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "section_tit" }, [
      _c("hr"),
      _vm._v("媒体报道"),
      _c(
        "a",
        {
          staticClass: "section_more",
          attrs: { href: "/news", target: "_blank" },
        },
        [_vm._v("查看更多")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }