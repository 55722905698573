<template>
  <div class="section">
    <h3 class="section_tit"><hr>合作伙伴</h3>
    <div class="partner clearfix">
      <a class="fl"><img class="lazy2" v-lazy="g_global_cdn + '/img/index/qinghua.877606e059767896.jpg?t=1'" alt=""></a>
      <a class="fl"><img class="lazy2" v-lazy="g_global_cdn + '/img/index/nankai.6888f49b443127d9.jpg?t=1'" alt=""></a>
      <a class="fl"><img class="lazy2" v-lazy="g_global_cdn + '/pc/images/index/cooperate/hg.jpg?t=1'" alt=""></a>
      <a class="fl"><img class="lazy2" v-lazy="g_global_cdn + '/pc/images/index/cooperate/tengx.jpg?t=2'" alt=""></a>
      <a class="fl"><img class="lazy2" v-lazy="g_global_cdn + '/pc/images/index/cooperate/wx.jpg?t=1'" alt=""></a>
      <a class="fl"><img class="lazy2" v-lazy="g_global_cdn + '/pc/images/index/cooperate/jd.jpg?t=1'" alt=""></a>
      <a class="fl"><img class="lazy2" v-lazy="g_global_cdn + '/pc/images/index/cooperate/jindie.jpg?t=2'" alt=""></a>
      <a class="fl"><img class="lazy2" v-lazy="g_global_cdn + '/pc/images/index/cooperate/jianshe.jpg?t=1'" alt=""></a>
      <a class="fl"><img class="lazy2" v-lazy="g_global_cdn + '/pc/images/index/cooperate/banfu.jpg?t=1'" alt=""></a>
      <a class="fl"><img class="lazy2" v-lazy="g_global_cdn + '/img/index/sequoia.f4cf611759229869.jpg?t=1'" alt=""></a>
      <a class="fl"><img class="lazy2" v-lazy="g_global_cdn + '/pc/images/index/cooperate/jingwei.jpg?t=1'" alt=""></a>
      <a class="fl"><img class="lazy2" v-lazy="g_global_cdn + '/img/index/gefei.e2b424ef6e3d6e59.jpg?t=1'" alt=""></a>
      <a class="fl"><img class="lazy2" v-lazy="g_global_cdn + '/pc/images/index/cooperate/tianshi.jpg?t=1'" alt=""></a>
      <a class="fl"><img class="lazy2" v-lazy="g_global_cdn + '/pc/images/index/cooperate/chuangtou.jpg?t=1'" alt=""></a>
      <a class="fl"><img class="lazy2" v-lazy="g_global_cdn + '/pc/images/index/cooperate/hongshulin.jpg?t=1'" alt=""></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Partners'
}
</script>

<style lang="scss" scoped>
.section {
  border-bottom:1px solid #e6e6e6;
  .section_tit {
    position:relative;
    font-size:20px;
    color:#3c3c3c;
    font-weight: 400;
    padding:30px 8px 20px;
    text-align: left;
    clear: both;
    hr {
      position:absolute;
      left:0;
      top:37px;
      width: 3px;
      border:none;
      height:18px;
      background-color:#8c7fee;
      font-size: 0
    }
    .section_more {
      position: absolute;
      right:0;
      top:38px;
      font-size:13px;
      color:#8c7fee;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &:last-child {
    border-bottom:none;
  }
  
  .partner {
    margin-right:-8px;
    padding-bottom:5px;
    a {
      font-size: 0;
      border:1px solid #e6e6e6;
      width:99px;
      height:63px;
      border-radius: 5px;
      -webkit-border-radius:5px;
      margin:0 8px 35px 0;
      text-align: center;
      line-height: 63px;
      img {
        max-width: 99px;
        max-height:63px;
        border-radius: 5px;
        -webkit-border-radius:5px;
        vertical-align: middle;
      }
    }
  }
}
</style>