var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "section" }, [
    _vm._m(0),
    _c("div", { staticClass: "partner clearfix" }, [
      _c("a", { staticClass: "fl" }, [
        _c("img", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy",
              value:
                _vm.g_global_cdn +
                "/img/index/qinghua.877606e059767896.jpg?t=1",
              expression:
                "g_global_cdn + '/img/index/qinghua.877606e059767896.jpg?t=1'",
            },
          ],
          staticClass: "lazy2",
          attrs: { alt: "" },
        }),
      ]),
      _c("a", { staticClass: "fl" }, [
        _c("img", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy",
              value:
                _vm.g_global_cdn + "/img/index/nankai.6888f49b443127d9.jpg?t=1",
              expression:
                "g_global_cdn + '/img/index/nankai.6888f49b443127d9.jpg?t=1'",
            },
          ],
          staticClass: "lazy2",
          attrs: { alt: "" },
        }),
      ]),
      _c("a", { staticClass: "fl" }, [
        _c("img", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy",
              value: _vm.g_global_cdn + "/pc/images/index/cooperate/hg.jpg?t=1",
              expression:
                "g_global_cdn + '/pc/images/index/cooperate/hg.jpg?t=1'",
            },
          ],
          staticClass: "lazy2",
          attrs: { alt: "" },
        }),
      ]),
      _c("a", { staticClass: "fl" }, [
        _c("img", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy",
              value:
                _vm.g_global_cdn + "/pc/images/index/cooperate/tengx.jpg?t=2",
              expression:
                "g_global_cdn + '/pc/images/index/cooperate/tengx.jpg?t=2'",
            },
          ],
          staticClass: "lazy2",
          attrs: { alt: "" },
        }),
      ]),
      _c("a", { staticClass: "fl" }, [
        _c("img", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy",
              value: _vm.g_global_cdn + "/pc/images/index/cooperate/wx.jpg?t=1",
              expression:
                "g_global_cdn + '/pc/images/index/cooperate/wx.jpg?t=1'",
            },
          ],
          staticClass: "lazy2",
          attrs: { alt: "" },
        }),
      ]),
      _c("a", { staticClass: "fl" }, [
        _c("img", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy",
              value: _vm.g_global_cdn + "/pc/images/index/cooperate/jd.jpg?t=1",
              expression:
                "g_global_cdn + '/pc/images/index/cooperate/jd.jpg?t=1'",
            },
          ],
          staticClass: "lazy2",
          attrs: { alt: "" },
        }),
      ]),
      _c("a", { staticClass: "fl" }, [
        _c("img", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy",
              value:
                _vm.g_global_cdn + "/pc/images/index/cooperate/jindie.jpg?t=2",
              expression:
                "g_global_cdn + '/pc/images/index/cooperate/jindie.jpg?t=2'",
            },
          ],
          staticClass: "lazy2",
          attrs: { alt: "" },
        }),
      ]),
      _c("a", { staticClass: "fl" }, [
        _c("img", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy",
              value:
                _vm.g_global_cdn + "/pc/images/index/cooperate/jianshe.jpg?t=1",
              expression:
                "g_global_cdn + '/pc/images/index/cooperate/jianshe.jpg?t=1'",
            },
          ],
          staticClass: "lazy2",
          attrs: { alt: "" },
        }),
      ]),
      _c("a", { staticClass: "fl" }, [
        _c("img", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy",
              value:
                _vm.g_global_cdn + "/pc/images/index/cooperate/banfu.jpg?t=1",
              expression:
                "g_global_cdn + '/pc/images/index/cooperate/banfu.jpg?t=1'",
            },
          ],
          staticClass: "lazy2",
          attrs: { alt: "" },
        }),
      ]),
      _c("a", { staticClass: "fl" }, [
        _c("img", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy",
              value:
                _vm.g_global_cdn +
                "/img/index/sequoia.f4cf611759229869.jpg?t=1",
              expression:
                "g_global_cdn + '/img/index/sequoia.f4cf611759229869.jpg?t=1'",
            },
          ],
          staticClass: "lazy2",
          attrs: { alt: "" },
        }),
      ]),
      _c("a", { staticClass: "fl" }, [
        _c("img", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy",
              value:
                _vm.g_global_cdn + "/pc/images/index/cooperate/jingwei.jpg?t=1",
              expression:
                "g_global_cdn + '/pc/images/index/cooperate/jingwei.jpg?t=1'",
            },
          ],
          staticClass: "lazy2",
          attrs: { alt: "" },
        }),
      ]),
      _c("a", { staticClass: "fl" }, [
        _c("img", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy",
              value:
                _vm.g_global_cdn + "/img/index/gefei.e2b424ef6e3d6e59.jpg?t=1",
              expression:
                "g_global_cdn + '/img/index/gefei.e2b424ef6e3d6e59.jpg?t=1'",
            },
          ],
          staticClass: "lazy2",
          attrs: { alt: "" },
        }),
      ]),
      _c("a", { staticClass: "fl" }, [
        _c("img", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy",
              value:
                _vm.g_global_cdn + "/pc/images/index/cooperate/tianshi.jpg?t=1",
              expression:
                "g_global_cdn + '/pc/images/index/cooperate/tianshi.jpg?t=1'",
            },
          ],
          staticClass: "lazy2",
          attrs: { alt: "" },
        }),
      ]),
      _c("a", { staticClass: "fl" }, [
        _c("img", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy",
              value:
                _vm.g_global_cdn +
                "/pc/images/index/cooperate/chuangtou.jpg?t=1",
              expression:
                "g_global_cdn + '/pc/images/index/cooperate/chuangtou.jpg?t=1'",
            },
          ],
          staticClass: "lazy2",
          attrs: { alt: "" },
        }),
      ]),
      _c("a", { staticClass: "fl" }, [
        _c("img", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy",
              value:
                _vm.g_global_cdn +
                "/pc/images/index/cooperate/hongshulin.jpg?t=1",
              expression:
                "g_global_cdn + '/pc/images/index/cooperate/hongshulin.jpg?t=1'",
            },
          ],
          staticClass: "lazy2",
          attrs: { alt: "" },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "section_tit" }, [
      _c("hr"),
      _vm._v("合作伙伴"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }