var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "section" }, [
    _vm._m(0),
    _c("div", { staticClass: "honor" }, [
      _c(
        "ul",
        {
          ref: "list",
          class: ["honor_ul", _vm.animate ? "anim" : ""],
          on: { mouseenter: _vm.mouseEnter, mouseleave: _vm.mouseLeave },
        },
        _vm._l(_vm.list, function (item) {
          return _c("li", { key: item.href, staticClass: "honor_li" }, [
            _c(
              "a",
              {
                staticClass: "honor_link",
                attrs: { href: item.href, target: "_blank" },
              },
              [
                _c("span", { staticClass: "honor_icon" }),
                _vm._v(_vm._s(item.title) + " "),
              ]
            ),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h3",
      { staticClass: "section_tit", staticStyle: { "padding-bottom": "15px" } },
      [_c("hr"), _vm._v("公司荣誉")]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }