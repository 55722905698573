<template>
  <div class="container" ref="container">
    <div id="sidebar" :class="panelFixed ? 'fixed' : ''" ref="service" :style="panelTop ? `top:${panelTop}px` : ''">
      <div class="section">
        <h3 class="section_tit"><hr>保险问诊服务</h3>
        <div class="answer">
          <div class="answer_banner">
            <img :src="g_global_cdn + '/pc/images/index/insurance_entrust.png'" alt="">
            <h2>不知道该选哪款保险？</h2>
            <h4>专属顾问为您定制保障方案</h4>
          </div>
          <ul class="answer_list">
            <li class="an1">
              <h4>定制家庭整体保险方案</h4>
              <p>根据您和家人的保障需求和经济状况，制定<em>家庭整体保险配置方案</em>。</p>
            </li>
            <li class="an2">
              <h4>市面产品方案对比</h4>
              <p>针对您关心的<em>保险类型</em>，或者您想提供对比的产品，进行重点产品对比。</p>
            </li>
            <li class="an3">
              <h4>已有保单诊断</h4>
              <p>为您和家人进行已购买的<em>保险诊断分析</em>。</p>
            </li>
          </ul>
          <a href="/pay/actPay?id=1004&chn=cg-pc" data-track="pcshouye" class="btn-more" target="_blank">立即预约</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Service',
  data () {
    return {
      panelTop: 0,
      panelFixed: false
    }
  },
  mounted () {
    this.calcFixed()
    document.addEventListener('scroll', () => {
      this.calcFixed()
    })
  },
  methods: {
    calcFixed () {
      const wrapper = document.getElementById('wrapper')
      const sidebarParent = document.getElementById('sidebarParent')
      const wrapperOffsetTop = wrapper.offsetTop - (document.documentElement.scrollTop || document.body.scrollTop)
      const panel = this.$refs.service

      if ((document.documentElement.scrollTop || document.body.scrollTop) >= this.$refs.container.offsetTop) {
        if ((panel.clientHeight - wrapperOffsetTop) > wrapper.offsetHeight) {
          if (this.panelFixed || document.documentElement.clientHeight >= panel.clientHeight) {
            this.panelFixed = false
            this.panelTop = wrapper.clientHeight - sidebarParent.clientHeight
          }
        } else {
          this.panelFixed = true
          this.panelTop = 0
        }
      } else {
        this.panelFixed = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 360px;
  min-height: 568px;
  z-index: 99;
}
.section {
  border-bottom:1px solid #e6e6e6;
  .section_tit {
    position:relative;
    font-size:20px;
    color:#3c3c3c;
    font-weight: 400;
    padding:30px 8px 20px;
    text-align: left;
    clear: both;
    hr {
      position:absolute;
      left:0;
      top:37px;
      width: 3px;
      border:none;
      height:18px;
      background-color:#8c7fee;
      font-size: 0
    }
    .section_more {
      position: absolute;
      right:0;
      top:38px;
      font-size:13px;
      color:#8c7fee;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &:last-child {
    border-bottom:none;
  }
  
  .answer {
    padding-bottom:40px;
    .answer_banner {
      position: relative;
      height: 148px;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
      h2 {
        position: absolute;
        top: 55px;
        left: 14px;
        font-size: 18px;
        font-weight: normal;
        line-height: 1;
        color: #666;
      }
      h4 {
        position: absolute;
        top: 80px;
        left: 14px;
        font-size: 16px;
        font-weight: normal;
        line-height: 1;
        color: #b0b0b0;
      }
    }
    .answer_list {
      margin-top: 24px;
      list-style: none;
      li {
        padding-left: 30px;
        padding-bottom: 17px;
        background-position: left top;
        background-repeat: no-repeat;
        background-size: 20px;
        &.an1 {
          background-image: url("../../../../assets/img/entrust_an1.png");
        }
        &.an2 {
          background-image: url("../../../../assets/img/entrust_an2.png");
        }
        &.an3 {
          background-image: url("../../../../assets/img/entrust_an3.png");
        }
        h4 {
          line-height: 20px;
          font-size: 16px;
          font-weight: normal;
          color: #3c3c3c;
        }
        p {
          margin-top: 7px;
          line-height: 20px;
          font-size: 14px;
          font-weight: normal;
          color: #969696;
          word-break: break-all;
        }
      }
    }
    .btn-more {
      display: block;
      width: 154px;
      line-height: 44px;
      margin: 0 auto;
      border-radius: 5px;
      font-size: 16px;
      text-align: center;
      background: #8c7fee;
      color: #fff;
    }
  }
}
#sidebar {
  min-height: 568px;
  width: 320px;
  padding: 0 20px;
  margin: 0 -20px;
  background: #fff;
  &.fixed {
    position: fixed;
    top: 0;
  }
}
</style>