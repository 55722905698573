var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isShow,
          expression: "isShow",
        },
      ],
      staticClass: "item clearfix",
      on: { click: _vm.toDetail },
    },
    [
      _c("div", { staticClass: "item_left" }, [
        _c(
          "a",
          {
            staticStyle: { width: "100%", height: "100%" },
            attrs: { href: _vm.item.src, target: "_blank" },
          },
          [
            _c("img", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy",
                  value: _vm.item.pic,
                  expression: "item.pic",
                },
              ],
              staticClass: "lazy item_img",
              attrs: { alt: _vm.item.title },
            }),
            _vm.item.recommendForNew == 1
              ? _c("i", { staticClass: "item_tag newuser" }, [
                  _c("span", { staticClass: "text" }, [_vm._v("新人优选")]),
                ])
              : _vm.item.hot == 1
              ? _c("i", { staticClass: "item_tag hot" }, [
                  _c("span", { staticClass: "text" }, [_vm._v("热卖")]),
                ])
              : _vm.item.isnew == 1
              ? _c("i", { staticClass: "item_tag new" }, [
                  _c("span", { staticClass: "text" }, [_vm._v("新品")]),
                ])
              : _vm.item.customPic
              ? _c("img", {
                  staticClass: "item_tag img",
                  attrs: { src: _vm.item.customPic },
                })
              : _vm._e(),
          ]
        ),
      ]),
      _c("div", { staticClass: "item_right" }, [
        _c(
          "div",
          { staticClass: "item_content" },
          [
            _c(
              "a",
              {
                staticClass: "item_tit",
                attrs: { href: _vm.item.src, target: "_blank" },
              },
              [
                _vm.item.diyTag
                  ? [
                      Number(_vm.item.diyTag) === 1
                        ? _c("img", {
                            staticClass: "product_tag",
                            attrs: {
                              src: require("../../../../assets/img/djlabel.png"),
                              alt: "小雨伞保险",
                            },
                          })
                        : _vm._e(),
                      Number(_vm.item.diyTag) === 2
                        ? _c("img", {
                            staticClass: "product_tag",
                            attrs: {
                              src: require("../../../../assets/img/dzlabel.png"),
                              alt: "小雨伞保险",
                            },
                          })
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(_vm.item.title))]),
              ],
              2
            ),
            _vm._l(_vm.item.advantage, function (adv, idx) {
              return _c("h3", { key: idx, staticClass: "item_tit_f" }, [
                _vm._v(_vm._s(adv)),
              ])
            }),
            _vm.tagList.length
              ? _c(
                  "div",
                  { staticClass: "item_tag_list" },
                  _vm._l(_vm.tagList, function (tagItem, tagIndex) {
                    return _c(
                      "section",
                      {
                        key: "tag" + tagIndex,
                        class: ["yellow", "" + tagItem.class, "tag_item"],
                      },
                      [_vm._v(" " + _vm._s(tagItem.text) + " ")]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.item.recommendInfo && _vm.item.recommendInfo.recommendWord
              ? _c("div", { staticClass: "item-recommend-info" }, [
                  _c("img", {
                    staticClass: "img",
                    attrs: { src: _vm.item.recommendInfo.picUrl },
                  }),
                  _c("span", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.item.recommendInfo.recommendWord)),
                  ]),
                  _c("img", {
                    staticClass: "end-icon",
                    attrs: {
                      src: require("../../../../assets/img/end-icon.png"),
                    },
                  }),
                ])
              : _vm._e(),
          ],
          2
        ),
        _c("div", { staticClass: "item_footer clearfix" }, [
          _c("div", { staticClass: "item_price" }, [
            _c("span", [_c("b", [_vm._v(_vm._s(_vm.item.itemPrice))])]),
            _c("p", { staticClass: "price-desc" }, [
              _c("span", [_vm._v(_vm._s(_vm.item.priceUnit))]),
              _vm.item.isBottomPrice
                ? _c("span", { staticClass: "lowest-price-tip" }, [
                    _vm._v("详见费率表"),
                  ])
                : _vm._e(),
            ]),
            _vm.item.commentTip >= 1
              ? _c("p", { staticClass: "comment" }, [
                  _vm._v(" " + _vm._s(_vm.item.commentTip) + "条评价 "),
                  _c("img", {
                    staticClass: "arrow-icon",
                    attrs: {
                      src: require("../../../../assets/img/right-arrow.png"),
                    },
                  }),
                ])
              : _vm._e(),
          ]),
          _c("div", [
            _c(
              "a",
              {
                staticClass: "btn pink",
                attrs: { href: _vm.item.src, target: "_blank" },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.item.saleTypeButtonText
                        ? _vm.item.saleTypeButtonText
                        : "立即投保"
                    ) +
                    " "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }