var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pc_page" },
    [_c("banner"), _c("index-content"), _c("download"), _c("percialization")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }