export function getParameter (name, str) {
  let r = new RegExp('(\\?|#|&)' + name + '=([^&#]*)(&|#|$)'); let m = (str || location.href).match(r)
  return decodeURIComponent(!m ? '' : m[2])
}

export function getCookie (key) {
  const r = new RegExp('(?:^|;+|\\s+)' + key + '=([^;]*)')
  const m = document.cookie.match(r)
  return (!m ? '' : m[1])
}

export function fadeOut(element, time) {
  var op = 1;  // initial opacity
  if (element.style.display === 'none') return
  var timer = setInterval(function () {
      if (op <= 0.1){
          clearInterval(timer);
          element.style.display = 'none';
      }
      element.style.opacity = op;
      element.style.filter = 'alpha(opacity=' + op * 100 + ")";
      op -= op * 0.1;
  }, time / 10);
}

export function fadeIn(element, time) {
  var op = 0.1;  // initial opacity
  if (element.style.display === 'block') return
  element.style.display = 'block';
  var timer = setInterval(function () {
      if (op >= 1){
          clearInterval(timer);
      }
      element.style.opacity = op;
      element.style.filter = 'alpha(opacity=' + op * 100 + ")";
      op += op * 0.1;
  }, time / 10);
}