<template>
  <div class="section">
    <h3 class="section_tit"><hr>保险知识<a href="/bxzs" target="_blank" class="section_more">查看更多</a></h3>
    <div class="section_tab">
      <div class="section_tab_hd clearfix">
        <div :class="['section_tab_nav', activeIndex === 0 ? 'active' : '']" @click="active(0)">重疾险<span class="arrow"></span></div>
        <div :class="['section_tab_nav', activeIndex === 1 ? 'active' : '']" @click="active(1)">医疗险<span class="arrow"></span></div>
        <div :class="['section_tab_nav', activeIndex === 2 ? 'active' : '']" @click="active(2)">意外险<span class="arrow"></span></div>
        <div :class="['section_tab_nav', activeIndex === 3 ? 'active' : '']" @click="active(3)">寿险<span class="arrow"></span></div>
      </div>
      <div class="section_tab_bd">
        <ul :class="['section_tab_list', activeIndex === 0 ? 'active' : '']">
          <li v-for="(art, index) in sick" :key="index" class="section_tab_li">
            <a :href="art.url" class="section_tab_link" target="_blank">{{ art.title }}</a>
          </li>
        </ul>
        <ul :class="['section_tab_list', activeIndex === 1 ? 'active' : '']">
          <li v-for="(art, index) in medical" :key="index" class="section_tab_li">
            <a :href="art.url" class="section_tab_link" target="_blank">{{ art.title }}</a>
          </li>
        </ul>
        <ul :class="['section_tab_list', activeIndex === 2 ? 'active' : '']">
          <li v-for="(art, index) in accident" :key="index" class="section_tab_li">
            <a :href="art.url" class="section_tab_link" target="_blank">{{ art.title }}</a>
          </li>
        </ul>
        <ul :class="['section_tab_list', activeIndex === 3 ? 'active' : '']">
          <li v-for="(art, index) in life" :key="index" class="section_tab_li">
            <a :href="art.url" class="section_tab_link" target="_blank">{{ art.title }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'KnowLedge',
  data () {
    return {
      activeIndex: 0
    }
  },
  computed: {
    ...mapState(['sick', 'medical', 'accident' , 'life'])
  },
  methods: {
    active (index) {
      this.activeIndex = index
    }
  }
}
</script>

<style lang="scss" scoped>
.section {
  border-bottom:1px solid #e6e6e6;
  .section_tit {
    position:relative;
    font-size:20px;
    color:#3c3c3c;
    font-weight: 400;
    padding:30px 8px 20px;
    text-align: left;
    clear: both;
    hr {
      position:absolute;
      left:0;
      top:37px;
      width: 3px;
      border:none;
      height:18px;
      background-color:#8c7fee;
      font-size: 0
    }
    .section_more {
      position: absolute;
      right:0;
      top:38px;
      font-size:13px;
      color:#8c7fee;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &:last-child {
    border-bottom:none;
  }

  .section_tab {
    border:1px solid #cfcfcf;
    margin-bottom:20px;
    .section_tab_hd {
      font-size:13px;
      text-align: center;
      background-color:#ebebeb;
      .section_tab_nav {
        position:relative;
        float: left;
        width: 25%;
        padding:11px 0;
        background-color:#ebebeb;
        color:#494949;
        cursor: pointer;
        &:before {
          content: "";
          position:absolute;
          left:0;
          top:11.5px;
          width: 1px;
          height:18px;
          background-color: #c3c3c3
        }
        &:first-child:before {
          width: 0
        }
        &.active {
          background-color:#8c7fee;
          color:#fff;
          &:before {
            background-color: #8c7fee;
          }
          & + .section_tab_nav:before {
            width: 0
          }
          .arrow {
            position:absolute;
            left:50%;
            bottom:-12px;
            margin-left:-6px;
            width:0;
            height:0;
            border-style: solid;
            border-width: 6px 6px 6px 6px;
            border-color:transparent;
            border-top-color:#8c7fee;
          }
        }
      }
    }
    .section_tab_bd {
      padding:5px 15px 20px;
      .section_tab_list {
        display: none;
        &.active {
          display: block;
        }
        .section_tab_li {
          font-size: 0;
          margin-top:15px;
        }
        .section_tab_link {
          font-size: 14px;
          color:#646464;
          display: inline-block;
          line-height: 1.4;
          &:hover {
            color:#8c7fee;
          }
        }
      }
    }
  }
}
</style>